html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	scroll-behavior: smooth;
	//zoom: 120%;
}
.startpage-content {
	margin-bottom: 50px;
}

body.desktop {
	#installWidget {
		display: none !important;
	}
	#newsWidget {
		display: block !important;
	}
}

body.pwa {
	#installWidget {
		display: none !important;
	}
	#newsWidget {
		display: block !important;
	}
}

body.pwa.ios {
	height: 100vh;
	.startpage-content {
		margin-bottom: 90px;
	}

	.fixed-top {
		.navbar {
			margin-top: 35px;
		}
	}
	#navbarMap {
		display: block !important;
		.navbar {
			margin-top: 15px;
		}
	}

	.btn-back-to-list {
		top: 60px;
		&.displayed {
			top: auto;
			bottom: 100px !important;
		}
	}

	.btn-back-to-top {
		&.displayed {
			bottom: 100px !important;
		}
	}
}

.blurry-overlay {
	display: none;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1060;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	background-color: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	//background: #ff0000;
}
body {
	//touch-action:none;
	padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
	//padding-top: env(safe-area-inset-top) !important;
	position: relative;
	background: url('../img/pflaster.jpg');
	background-size: cover;
	background-position: center;
	background-color: #222;
}

#installWidget {
	#dismissButton {
		color: #375a7f;
		border: 1px solid #375a7f;
	}
}

.tooltip {
	font-size: 1rem;
	&.show {
		opacity: 1;
	}
	.tooltip-inner {
		background-color: #375a7f;
		max-width: 300px;
		padding: 20px;
	}
	.arrow:before {
		border-top-color: #375a7f;
	}
}
.tooltipHolder {
	position: fixed;
	bottom: 0;
	left: 0px;
	width: 100%;
	span {
		display: none;
	}
}
#content {
	a {
		word-wrap: break-word;
	}

	p {
		line-height: 1.5 !important;
	}
}

#locationList,
#contentList,
#newsList {
	padding-bottom: 70px;
}
#contentList {
	p,
	.btn {
		display: none;
	}
}

.table {
	&.table-bordered {
		border: 0px;
		display: block;
		width: 100%;
		overflow-x: auto;
	}
	tr:first-child {
		td {
			//font-weight: bold;
		}
	}

	td,
	th {
		border: 0px;
		color: #303030;
		border-top: 1px solid #444;
	}

	td:nth-child(2) {
		//td:last-child {
		// -ms-word-break: break-all;
		// word-break: break-all;

		/* Non standard for webkit */
		word-break: break-word;

		-webkit-hyphens: manual;
		-moz-hyphens: manual;
		-ms-hyphens: manual;
		hyphens: manual;
	}
}

.popover-body {
	max-width: 250px;
	text-align: center;
}

.debug-content {
	background: #375a7f;
	right: 0;
	font-size: 10px;
	position: absolute;
	display: block;
	padding: 0 5px;
	z-index: 9999;
	top: 5px;
	display: inline-block;
	width: 40px;
	left: auto;
	//border-radius: 5px 0px 0px 5px;

	code {
		color: white;
	}
}

.map {
	&.map-fullscreen {
		margin-left: -24px;
		padding-bottom: 55px;
		width: 100%;
		height: 100%;
		position: fixed;
	}

	.filtered-layer {
		filter: grayscale(0.7188755020080321);
	}

	.ol-attribution {
		font-size: xx-small;
	}
}

.note-float-left {
	margin: 4px 4px 4px 0px;
}
.note-float-right {
	margin: 4px 0px 4px 4px;
}
.hintergrund,
.aktuell,
.spur {
	margin-bottom: 60px;
}

.btn-back-to-list {
	position: fixed;
	display: none;
	z-index: 99;
	left: 12px;
	top: 12px;
	bottom: auto;
	transition: all 0.5s ease;

	&.displayed {
		top: auto;
		display: block;
		bottom: 65px !important;
	}
}

.btn-back-to-top {
	position: fixed;
	display: none;
	z-index: 99;
	right: 12px;
	left: auto;
	bottom: 0;
	transition: all 0.5s ease;
	top: auto;

	&.displayed {
		top: auto;
		display: block;
		bottom: 65px !important;
	}
}

.spur {
	#map {
		height: 25vh;
		border-bottom: 1px solid black;
		z-index: 999;
	}

	// #content {
	// 	height: 50vh;
	// 	margin-left: -24px;
	// 	margin-right: -24px;
	// 	padding-right: 24px;
	// 	padding-left: 24px;
	// 	overflow-y: auto;
	// }
	.carousel-indicators {
		position: absolute;
	}
	.carousel-item {
		border-bottom: 1px solid black;

		text-align: center;
		//	height: 40vh;
		//	overflow-y: auto;
	}

	.carousel-control-prev {
		//left: -52px;
	}

	.carousel-control-next {
		//right: -52px;
	}

	// .carousel-control-prev-icon {
	// 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
	// }

	// .carousel-control-next-icon {
	// 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
	// }
	// .carousel-control-next,
	//  .carousel-control-prev /*, .carousel-indicators */ {
	// 	filter: invert(100%);
	// }
	// .scrollable {
	// }

	img {
		width: 100%;
	}
}

a.no-underline {
	text-decoration: none !important;
}

.modal-dialog {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 10px;
	max-width: 100%;
}

.modal-content {
	height: auto;
	min-height: 100%;
	border-radius: 0;
}

.home-header {
	height: 380px;

	h1,
	h2 {
		text-shadow: 2px 3px 3px black;
		color: #eaeaea;
	}

	h2 {
		font-size: 2rem;
	}
}

.tile {
	padding-right: 7px;
	padding-left: 7px;
	text-transform: uppercase;

	> div {
		border-radius: 4px;
	}
}

.bg-light {
	background-color: #ffffffba !important;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.bg-light,
	.bg-dark,
	.rotation-info {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
	}
}

.bg-light-solid-incl-watermark {
	background: url('../img/pflaster.jpg');
	background-size: cover;
	background-position: center;
	background-color: #ffffffba !important;
}

// .modal-content {
//   &.bg-light {
//     background-color: #ededed !important;
//   }
// }

.bg-dark {
	background-color: rgba(48, 48, 48, 0.9) !important;
}

.clickable {
	cursor: pointer;
	&.selected {
		background-color: #ffffffe6 !important;
	}
}

.fixed-top {
	.navbar {
		// padding-top: env(safe-area-inset-top) !important;
		// padding-right: env(safe-area-inset-right) !important;
		// padding-left: env(safe-area-inset-left) !important;
	}
}

.fixed-bottom {
	.navbar {
		padding: 0px;
		padding-bottom: env(safe-area-inset-bottom) !important;
	}
}

.navbar {
	font-size: 0.6rem;

	.nav-item {
		//width: 20%;
		text-transform: uppercase;
	}
}

.rotation-info {
	display: none;
	animation: pulse 2s ease 0s infinite;
}

// .desktop {
// 	.container-fluid {
// 		max-width: 600px;
// 	}
// 	.fixed-bottom,
// 	.fixed-top {
// 		.navbar {
// 			max-width: 600px;
// 			margin: 10px auto;
// 		}
// 	}
// }

.mobile {
	.container-fluid {
		max-width: 100%;
	}
	.container-fluid-bg {
		display: none;
	}
	.spur {
		.rotation-info {
			display: block;
			position: absolute;
			width: 50px;
			bottom: 30px;
			right: 5px;
			background: white;
			padding: 7px;
			border-radius: 12px;
		}
	}
}

iframe {
	max-width: 100%;
}

@keyframes pulse {
	0% {
		opacity: 0.9;
		transform: scale(0.9);
	}

	50% {
		opacity: 0.9;
		transform: scale(1);
	}

	100% {
		opacity: 0.9;
		transform: scale(0.9);
	}
}

.card {
	background: none;
	border: 0px;
	border-radius: 0px;
	.card-body {
		border-bottom: 5px solid #375a7f;
		padding-right: 0px;
		padding-left: 0px;
	}
	.card-header {
		padding: 0px;
		background: #375a7f;
		border: 0px !important;
		cursor: pointer;
		border-radius: 0px;
		margin-top: 5px;

		.card-link {
			padding: 0.75rem 1.25rem;
			display: block;
			color: white;
			font-size: 16px;
			&.collapsed {
				&::before {
					content: '+';
				}
			}
			&::before {
				content: '-';
				width: 18px;
				display: inline-block;
			}
		}
	}
}

audio {
	width: 100%;
	max-width: 100%;
	display: block;
}

@media screen and (orientation: landscape) {
	.mobile {
		background: #222;
		.map-spur,
		.footer-spur,
		.content-spur,
		.rotation-info {
			display: none !important;
		}

		.spur {
			padding: 0 !important;
			margin: 0 !important;
			// margin-right: -1.5rem !important;
			// margin-left: -1.5rem !important;
			.carousel {
				margin-left: -1.5rem !important;
				margin-right: -1.5rem !important;
				background: #222;
				.carousel-item {
					border-bottom: 0px;
					width: 100%;
					text-align: center;
					img {
						//two options: full width or full height
						/*width: auto;
						max-width: 100vw;
						max-height: 100vh;*/

						width: 100%;
						/* max-width: 100vw; */
						/* max-height: 100vh; */
					}
				}
			}

			.carousel-control-next,
			.carousel-control-prev {
				//	display: none;
			}
			.carousel-indicators {
				position: fixed !important;
				top: 0px;
				max-height: 20px;
			}
		}
	}
	.orientation-notification {
		display: block !important;
		position: fixed;
		text-align: center;
		background-color: lightgrey;
		width: 100%;
		height: 100%;
		z-index: 9999;
		left: 0px;
		top: 0px;
		.rotation-info {
			width: 15vh;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
			margin-top: 40vh;
			margin-left: auto;
			margin-right: auto;
			display: block;
			position: relative;
			display: block !important;
		}
	}

	body.keyboard-showing {
		.orientation-notification {
			display: none !important;
		}
	}
}

@media screen and (orientation: portrait) {
	.carousel-inner {
		max-height: 30vh;
	}

	.carousel {
		height: 30vh;
		overflow: hidden;
	}
}
